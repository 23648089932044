import axios from 'axios';
import { useQuery } from 'react-query';

import {
  GET_ORDER_DETAILS_REFETCH_INTERVAL,
  ORDER_MICRO_SERIVCE,
} from '../constants';

interface IQueryParams {
  orderId: number;
  isFetchingOrderHistory?: boolean;
  disable?: boolean;
}

const getOrderDetails = async ({ orderId }: IQueryParams) => {
  const response = await axios.get(
    `${ORDER_MICRO_SERIVCE}/orders/${orderId}?details=payment,store,pickup,tracking,am_here`,
  );
  return response?.data?.data;
};

export default function useOrderDetails({
  orderId,
  isFetchingOrderHistory,
  disable = false,
}: IQueryParams) {
  return useQuery(
    ['orderdetails', orderId],
    () => getOrderDetails({ orderId }),
    {
      enabled: disable? !disable && !isFetchingOrderHistory && !!orderId : !isFetchingOrderHistory && !!orderId,
      refetchInterval: Number(GET_ORDER_DETAILS_REFETCH_INTERVAL),
      cacheTime: 0,
      staleTime: 0,
    },
  );
}
