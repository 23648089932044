import { useQuery } from 'react-query';

import { customerBusinessService } from '../../services';
import { useAppSelector } from 'src/redux/store/store';

const getOffers = async (params: any) => {
  const response = await customerBusinessService.getOffers(params);
  return response.data.data;
};

export default function useGetOffers(
  enabled: boolean,
  moduleName: 'CartModal' | 'RewardsPage',
) {
  const isEditOrder = useAppSelector((state) => state.cart.is_edit_order);
  const orderId = useAppSelector((state) => state.cart.orderId);
  const params = isEditOrder
    ? {
        order_id: orderId,
      }
    : undefined;
  return useQuery<any>(['offers', moduleName], () => getOffers(params), {
    cacheTime: 0,
    enabled: enabled,
  });
}
