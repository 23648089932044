import { useEffect, useState } from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  ChevronIcon,
  CZLogo,
  LocationFilledIcon,
  LocationOutlineIcon,
  DeliveryIconLocation,
  GeoLocationWhiteIcon,
  DeliveryIconMobile,
  CartFilledIcon,
  CartOutlineIcon,
} from 'src/assets/images/Svgs/svg';
import SignUpButton from 'src/components/AccountStatus/SignUpButton';
import CustomModal from 'src/components/customModal/CustomModal';
import ItemOrderModal from 'src/components/ItemOrderModal/ItemOrderModal';
import Loader from 'src/components/Loader/Loader';
import NavbarComponent from 'src/components/NavbarComponent/NavbarComponent';
import * as CONSTANT from 'src/constants';
import UserName from 'src/Features/AccountSetup/components/UserName';
import { guestLogin } from 'src/Features/AccountSetup/redux/actions';
import Cart from 'src/Features/Cart/Cart';
import { getUser, getVisitorId } from 'src/helper/helperMethods';
import useGetCustomerPlacedOrders from 'src/react-query-hooks/useGetCustomerPlacedOrders';
import { setShowCart } from 'src/redux/slices/cartSlice';
import { useAppSelector } from 'src/redux/store/store';

import styles from './header.module.scss';
import LiveOrder from './LiveOrder';
import MultiOrderModal from './MultiOrderModal';
import TopHeaderBar from './TopHeaderBar';
import MegaMenuDropDown from 'src/components/MegaMenuDropDown/MegaMenuDropDown';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';

const Header = () => {
  const navLinks = [
    {
      item: 'loyalty',
      href: '/loyalty',
    },
    {
      item: 'CAREERS',
      href: 'https://cafezupas.com/careers',
    },
    {
      item: 'FUNDRAISERS',
      href: 'https://cafezupas.com/fundraising',
    },
    {
      item: 'Individual',
      href: 'https://cafezupas.com/',
    },
  ];

  const { user } = useAppSelector((state) => state?.user);
  const cart = useAppSelector((state) => state.cart);
  const cartItems = cart.items;
  const show = cart.showCart;
  const setShow = (show) => {
    dispatch(setShowCart(show));
  };

  const handleClick = (href: string) => {
    if (
      href === 'https://cafezupas.com/' ||
      href.startsWith('https://cafezupas.com/')
    ) {
      window.open(href, '_blank');
    } else {
      history.push(href);
    }
  };

  const locationInfo = useAppSelector((state) => state.location);
  const isActiveOrders = useAppSelector(
    (state) => state.orderDetails.futureOrder.activeOrders,
  );
  const location = useLocation();
  const [showLocationSearch, setShowLocationSearch] = useState<boolean>(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [multiOrderModal, setMultiOrderModal] = useState(false);
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const history = useHistory();
  const isItMobile = useCheckMobileScreen();
  const [openMegaMenu, setOpenMegaMenu] = useState<boolean>(false);
  const [megaMenuAnimateClass, setMegaMenuAnimateClass] = useState('');
  const [rotateMenuIcon, setRotateMenuIcon] = useState('');
  const [addShadowOnScroll, setAddShadowOnScroll] = useState(false)
  const [addressConfirmationModal, setAddressConfirmationModal] = useState<{
    showModal: boolean;
    showCart: boolean;
  }>({ showModal: false, showCart: false });

  const {
    refetch: refetchGetCustomerPlacedOrders,
    data: { data: placedOrders = [] } = [],
    isFetching: isFetchingPlacedOrders,
    refetch: fetchItem,
  } = useGetCustomerPlacedOrders({ userId: user?.id });
  const isPublicTrackingRoute = location.pathname.includes(
    CONSTANT.ROUTE_ORDER_TRACKING,
  );

  useEffect(() => {
    if (multiOrderModal || location.pathname === '/thankYou') {
      fetchItem();
    }
  }, [multiOrderModal, location]);

  const shouldShowLiveTracker =
    !isFetchingPlacedOrders &&
    placedOrders?.length &&
    !isPublicTrackingRoute &&
    isActiveOrders;
  const authenticateGuest = async () => {
    try {
      setAuthLoading(true);
      const payload = {
        visitor_id: getVisitorId(),
      };
      await guestLogin(dispatch, payload);
      setAuthLoading(false);
      return true;
    } catch (error) {
      setAuthLoading(false);
      return false;
    }
  };



  useEffect(() => {
    window.addEventListener("scroll", () => {
      setAddShadowOnScroll(window.scrollY > 20);
    })
  }, []);


  const HandleShowMegaMenu = () => {
    setOpenMegaMenu(true);

    setTimeout(() => {
      setMegaMenuAnimateClass('opacity-100');
      setRotateMenuIcon(styles.rotate180);
    }, 0);
  };

  const HandleHideMegaMenu = () => {
    setMegaMenuAnimateClass('opacity-0');
    setRotateMenuIcon(styles.rotate360);
    setTimeout(() => {
      setOpenMegaMenu(false);
    }, 300);
  };

  const verifyAddress = () => {
    return (
      cart.orderType === CONSTANT.DELIVERY_ORDER_TYPE &&
      user.type !== CONSTANT.GUEST_USER &&
      cart?.orderDetails?.delivery_address?.isGuestAddress
    );
  };
  const handleLocationButton = () => {
    if (verifyAddress()) {
      setAddressConfirmationModal({ showModal: true, showCart: false });
      return;
    }
    setShowLocationSearch(true);
    setOpenOrderModal(true);
  };
  const viewCartHandler = async () => {
    if (location.pathname.includes(CONSTANT.ROUTE_CHECKOUT)) {
      history.push(CONSTANT.ROUTE_MENU);
      setShow(true);
    } else {
      if (authLoading) return;
      if (!cart.isOrderTypeSelected) {
        setOpenOrderModal(true);
      } else if (verifyAddress())
        setAddressConfirmationModal({ showModal: true, showCart: true });
      else setShow(true);
    }
  };
  const locationLabel = () => {
    if (cart.isOrderTypeSelected) {
      return cart.orderType == CONSTANT.DELIVERY_ORDER_TYPE ? (
        <>
          {' '}
          {isItMobile ? <DeliveryIconMobile /> : <DeliveryIconLocation />}
          <span className={styles.locationLabel}>Delivery </span>
        </>
      ) : (
        (
          <>
            {' '}
            {isItMobile ? <GeoLocationWhiteIcon /> : <LocationFilledIcon />}
            <span className={styles.locationLabel}>
              {locationInfo?.selectedStore?.name}{' '}
            </span>{' '}
          </>
        ) ?? 'LOCATIONS'
      );
    } else
      return (
        <>
          {' '}
          {isItMobile ? <GeoLocationWhiteIcon /> : <LocationOutlineIcon />}
          <span className={styles.locationLabel}>
            {' '}
            {'FIND A CAFE ZUPAS'}
          </span>{' '}
        </>
      );
  };
  const closeOpenOrderModal = () => {
    setOpenOrderModal(false);
  };

  useEffect(() => {
    if (!user?.id && !authLoading) authenticateGuest();
  }, [user?.id]);
  const dispatch = useDispatch();
  const userId = user?.id ?? '';
  const userType = user?.type ?? '';

  return (
    <>
      <TopHeaderBar
        handleLocationButton={handleLocationButton}
        locationLabel={locationLabel}
      />
      <div className={`${styles.headerWrapper} ${addShadowOnScroll ? styles.Headershadow : null}`}>
        <Container fluid className={`${styles.headerContainer} ps-md-1 pe-md-3 px-3`}>
          <Row className={styles.headerRow}>
            <Col xs={12} className="p-0">
              <div className="d-flex justify-content-start w-100 align-items-center ">
                <NavLink
                  className={` ${styles.navLogoLink} d-none d-md-block`}
                  href="/"
                >
                  <CZLogo className="sticky-logo" />
                </NavLink>
                <div
                  className={`d-flex w-100 justify-content-between ${styles.headerItems}`}
                >
                  {!isItMobile && (
                    <div className="d-flex align-items-center ps-3">
                      <span
                        onMouseOver={HandleShowMegaMenu}
                        onMouseLeave={HandleHideMegaMenu}
                        className="d-inline"
                      >
                        <a
                          className="clr-lt-black header-menu-font text-decoration-none text-nowrap text-uppercase pointer"
                          onClick={() => history.push(CONSTANT.ROUTE_MENU)}
                        >
                          menu
                          <span>
                            {' '}
                            <ChevronIcon className={`${rotateMenuIcon}`} />{' '}
                          </span>
                        </a>

                        {openMegaMenu && (
                          <MegaMenuDropDown
                            HandleHideMegaMenu={HandleHideMegaMenu}
                            megaMenuAnimateClass={megaMenuAnimateClass}
                          />
                        )}
                      </span>
                      <div>
                        {navLinks.map((item: any, i: any) => {
                          return (
                            <a
                              key={i}
                              className="clr-lt-black header-menu-font text-decoration-none text-nowrap text-uppercase pointer"
                              onClick={() => handleClick(item.href)}
                            >
                              {item.item}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div
                    className={`${styles.main_nav_buttons} d-md-flex d-none  align-items-center justify-content-center`}
                  >
                    <button
                      type="button"
                      className={`${styles.locationNavSection} d-flex justify-content-center align-items-center`}
                      onClick={handleLocationButton}
                    >
                      {locationLabel()}
                    </button>
                    {userId && userType !== CONSTANT.GUEST_USER ? (
                      <UserName userType={userType} userId={userId} userRefreshToken={user?.refresh_token} />
                    ) : (
                      <SignUpButton />
                    )}
                    <button
                      type="button"
                      className={`${styles.add_to_card_btn} font-Vcf d-flex align-items-end justify-content-center clr-red-dark`}
                      onClick={viewCartHandler}
                    >
                      {cartItems.length ? (
                        <CartFilledIcon />
                      ) : (
                        <CartOutlineIcon />
                      )}
                      <span className={cartItems.length && 'clr-white'}>
                        {' '}
                        {cartItems.length}{' '}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {isItMobile && (
                <div className="d-flex align-items-center justify-content-between">
                  <NavbarComponent />

                  <NavLink className={` ${styles.navLogoLink}`} href="/">
                    <CZLogo className="sticky-logo" />
                  </NavLink>
                  <button
                    type="button"
                    className={`${styles.add_to_card_btn} font-Vcf d-flex align-items-end justify-content-center clr-red-dark`}
                    onClick={viewCartHandler}
                  >
                    {cartItems.length ? (
                      <CartFilledIcon />
                    ) : (
                      <CartOutlineIcon />
                    )}
                    <span className={cartItems.length && 'clr-white'}>
                      {' '}
                      {cartItems.length}{' '}
                    </span>
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <div>
          {!!shouldShowLiveTracker && (
            <LiveOrder setMultiOrderModal={setMultiOrderModal} />
          )}

          <CustomModal
            showModal={multiOrderModal}
            title={''}
            closeModal={() => setMultiOrderModal(false)}
            size="xl"
            modalDialogClasses="select_date_time"
          >
            {isFetchingPlacedOrders ? (
              <Loader modalSpinner={true} />
            ) : (
              <MultiOrderModal
                data={placedOrders}
                setMultiOrderModal={setMultiOrderModal}
              />
            )}
          </CustomModal>
          <CustomModal
            showModal={openOrderModal}
            title={''}
            closeModal={closeOpenOrderModal}
            modalDialogClasses={'itemOrderModal'}
            modalBodyClass={'overflow-visible'}
          >
            <ItemOrderModal
              itemPath={null}
              closeOpenOrderModal={closeOpenOrderModal}
              searchLocations={showLocationSearch}
              goBack={false}
              showModal={openOrderModal}
            />
          </CustomModal>
        </div>
      </div>
      {show ? <Cart /> : null}
    </>
  );
};

export default Header;
