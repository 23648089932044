import { useMutation } from "react-query"; 
import { IRefreshTokenPayload } from "../../models/forms.model";
import axios from "axios";

import { CUSTOMER_MICRO_SERVICE } from "../../constants";

const getNewAuthToken = async (data: IRefreshTokenPayload) => {
    const response = await axios.post(`${CUSTOMER_MICRO_SERVICE}/auth/refresh-token`, data, {
        headers: {
            'Authorization': "token",
            'token': "token"
        }
    })
    return response.data.data;
};

export default function useRefreshAuthToken() {
    return useMutation((data: IRefreshTokenPayload) =>
        getNewAuthToken(data)
    );
}
