import { useEffect, useState } from 'react';
import { Offcanvas, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {
  CopyLinkIcon,
  ImportCsvIcon,
  LocationArrow,
  ShareLinkIcon,
} from 'src/assets/images/Svgs/svg';
import CustomModal from 'src/components/customModal/CustomModal';
import { isGuestUser, isSignedInUser } from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import useRedeemOffer from 'src/hooks/useRedeemOffer';
import useSyncCart from 'src/hooks/useSyncCart';
import {
  IDeleteOrderItemPayload,
  IPromoCode,
  IUpdateOrderQuantityPayload,
} from 'src/models/order.model';
import { IOfferItem } from 'src/models/rewards.model';
import useDeleteItemFromCart from 'src/react-query-hooks/Cart/useDeleteItem';
import usePromoCode from 'src/react-query-hooks/Cart/usePromoCode';
import useUpdateItemQuantity from 'src/react-query-hooks/Cart/useUpdateItemQuantity';
import useGetOffers from 'src/react-query-hooks/Loyalty/useGetOffers';
import {
  deleteItemFromOrder,
  resetCartOffer,
  setShowCart,
  updateCartRedeemedOfferItem,
  updateItemQuantity,
} from 'src/redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';

import FormField from 'src/components/FormFields/FormField';
import useExitEditOrder from 'src/react-query-hooks/useExistEditOrder';
import Billing from './Billing/Billing';
import CartItemsList from './CartItems/CartItemsList';
import GroupOrderCartDetailCard from './GroupOrderCartDetailCard/GroupOrderCartDetailCard';
import OfferEligibilityModal from './Offers/OfferEligibilityModal';
import OfferItemsList from './Offers/OfferItemsList';
import ShareLink from './ShareLink';
import ShareLinkModal from './ShareLink/ShareLinkModal/ShareLinkModal';
import styles from './cart.module.scss';
import { INearestLocationQueryParams } from 'src/models/location.model';
import { DELIVERY_ORDER_TYPE, GUEST_USER } from 'src/constants';
import { locationMenuService } from 'src/services';
import { setLocation } from '../Location/redux/slice';
import { brazeLogEventClaimPromoCode } from 'src/helper/brazeHelper';

enum ShareModal {
  ShareLink = 'ShareLink',
  ImportCsv = 'ImportCsv',
  close = 'close',
}

const Cart = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const showCart = useAppSelector((state) => state.cart.showCart);
  const cart = useAppSelector((state) => state.cart);
  const cartItems = cart.items;
  const isCartEmpty = !cartItems.length;
  const { user } = useAppSelector((state) => state.user);
  const locationInfo = useAppSelector((state) => state.location);
  const location_id = locationInfo.selectedStore?.id;

  const { syncCart, loading: syncingCart } = useSyncCart();

  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };
  const [promoCode, setPromoCode] = useState<string>('');
  const { mutate: applyPromoCode, isLoading: isApplyingCoupon } =
    usePromoCode();

  const { mutate: deleteItem, isLoading: isDeleting } = useDeleteItemFromCart();
  const { mutate: updateQuantity, isLoading: isUpdatingQuantity } =
    useUpdateItemQuantity();

  const syncCartWihOffer = () => {
    syncCart(location_id, false, selectedOfferItem);
  };

  useEffect(() => {
    if (user?.id || location_id) {
      syncCartWihOffer();
    }
  }, [location_id, cart?.orderType]);

  useEffect(() => {
    dispatch(resetCartOffer());
    dispatch(
      updateCartRedeemedOfferItem({
        is_redeemed_by_offer: false,
      }),
    );
    if (cart?.orderType === DELIVERY_ORDER_TYPE) {
      checkNearestStoreLocation(cart?.orderDetails?.delivery_address);
    }
  }, []);

  const hideCart = () => {
    dispatch(setShowCart(false));
  };

  const isGroupOrderPage = ['/groupOrder'];
  const showGroupCheckoutBtn = !isGroupOrderPage.includes(location.pathname);

  const [shareLinkModal, setShareLinkModal] = useState<ShareModal>(
    ShareModal.close,
  );
  const isCSv = shareLinkModal === ShareModal.ImportCsv;
  const copyHandler = () => {
    navigator.clipboard.writeText(window.location.href);
    Toast_Func({
      status: true,
      message: 'Link Copied, Link added to clip board',
    });
  };

  const [offerEligibilityModal, setOfferEligibilityModal] = useState<{
    show: boolean;
    failedOfferCriteria: string;
  }>({
    show: false,
    failedOfferCriteria: '',
  });
  const [subTotal, setSubTotal] = useState<number>(0);
  const locationDispatch = useAppDispatch();
  const [isPromoOfferAdded, setIsPromoOfferAdded] = useState<boolean>(false);
  const [onCartOpenSelectOffer, setOnCartOpenSelectOffer] =
    useState<boolean>(true);
  const [previousSelectedOffer, setPreviousSelectedOffer] =
    useState<IOfferItem>(null);
  const { redeemOffer, removeOffer } = useRedeemOffer();
  const {
    data: allOffers = {},
    isFetching,
    refetch: refetchOffers,
    isRefetching: isRefetchingOffers,
  } = useGetOffers(!isGuestUser(user) && showCart, 'CartModal');

  const offers = allOffers?.rewards || [];

  const cartRedirectOfferId = cart.redirect_offer_id;
  const cartRedirectOffer: IOfferItem = offers?.find(
    (offer: IOfferItem) => offer.id === cartRedirectOfferId,
  );

  const latestOffer = offers?.[0];
  const selectedOfferItem: IOfferItem = offers?.find(
    (offer: IOfferItem) => offer.in_cart,
  );

  useEffect(() => {
    if (
      !isFetching &&
      !syncingCart &&
      (isPromoOfferAdded || onCartOpenSelectOffer)
    ) {
      const offerToBeSelected = cartRedirectOffer || latestOffer;
      if (!offerToBeSelected) return;
      const { showModalOrCart, failedOfferCriteria } = redeemOffer({
        offerItem: offerToBeSelected,
        cartItems,
        selectedOfferItem,
        subTotal,
      });
      if ((!onCartOpenSelectOffer || cartRedirectOffer) && showModalOrCart) {
        const findPreviousOffer = offers?.find(
          (offer: IOfferItem) => offer.id === previousSelectedOffer?.id,
        );
        if (findPreviousOffer) {
          findPreviousOffer.in_cart = true;
          findPreviousOffer.redeemed_by_offer_discount =
            previousSelectedOffer.redeemed_by_offer_discount;
          findPreviousOffer.cart_item_index =
            previousSelectedOffer.cart_item_index;
        }
        setOfferEligibilityModal({
          show: true,
          failedOfferCriteria,
        });
      }
      setPreviousSelectedOffer(null);
      setIsPromoOfferAdded(false);
      setOnCartOpenSelectOffer(false);
    }
  }, [isFetching, syncingCart]);

  const isCouponCodeEntered = promoCode?.toString()?.length > 0 ? false : true;
  const handlePromoCode = async () => {
    const payload: IPromoCode = {
      promo_code: promoCode,
    };
    applyPromoCode(payload, {
      onSuccess: async (res: any) => {
        if (res?.data?.rewards?.length) {
          refetchOffers();
          setIsPromoOfferAdded(true);
          setPreviousSelectedOffer(selectedOfferItem);
        }
        brazeLogEventClaimPromoCode(promoCode, user?.id, cart?.cartId);
      },
      onError: (error: any) => {
        Toast_Func({
          status: false,
          message: error?.response?.data?.errors[0],
        });
      },
    });
  };
  const applyPromoCodeIcon = () => {
    if (isApplyingCoupon || isRefetchingOffers) {
      return (
        <div className={`${styles.loader_wrapper}`}>
          <Spinner
            animation="border"
            variant="dark"
            size="sm"
            className={styles.spinner}
          />
        </div>
      );
    }
    return (
      <button
        onClick={() => handlePromoCode()}
        type="button"
        className={`${styles.arrow_btn} btn btn-link calenderIcon calenderIconCart pointer`}
        disabled={isCouponCodeEntered}
        // disabled={true}
      >
        {' '}
        <LocationArrow className="" height={29} width={29} />{' '}
      </button>
    );
  };

  const reApplyOffer = (iteratingIndex = -1) => {
    selectedOfferItem.in_cart = false;
    const { failedOfferCriteria } = redeemOffer({
      offerItem: selectedOfferItem,
      cartItems,
      selectedOfferItem,
      subTotal,
      deletedItemIndex: iteratingIndex,
    });
    if (failedOfferCriteria) removeOffer(selectedOfferItem, true);
  };

  const handleItemRemove = async (item_id, iteratingIndex, closeModal) => {
    const payload: IDeleteOrderItemPayload = {
      item_id: item_id,
    };
    payload.customer_id = user.id;
    deleteItem(payload, {
      onSuccess: () => {
        if (cartItems[iteratingIndex].is_redeemed_by_offer) {
          reApplyOffer(iteratingIndex);
        }
        dispatch(deleteItemFromOrder(iteratingIndex));
        closeModal();
      },
      onError: (error) => {
        closeModal();
      },
    });
  };

  const handleQuantityChange = async (
    item_id,
    iteratingIndex,
    itemQuantity: number,
  ) => {
    const payload: IUpdateOrderQuantityPayload = {
      item_id,
      quantity: itemQuantity,
    };
    dispatch(
      updateItemQuantity({
        index: iteratingIndex,
        quantity: itemQuantity,
      }),
    );
    payload.customer_id = user.id;
    updateQuantity(payload, {
      onError: (error) => {
        console.log(error, 'ERROR ON quantity change');
      },
    });
  };

  const checkNearestStoreLocation = async (address) => {
    const payload: INearestLocationQueryParams = {
      delivery: true,
      city: address.city,
      state: address.state,
      street: address.street,
      zipcode: address.zipcode,
      delivery_address:
        user.type === GUEST_USER
          ? address?.fullAddress ?? address?.full_address
          : address?.full_address,
    };
    const response = await locationMenuService.getNearbyLocations(payload);
    if (response?.data?.data[0]) {
      locationDispatch(setLocation(response?.data?.data[0]));
    }
  };

  return (
    <>
      {(cartRedirectOffer || latestOffer) && (
        <OfferEligibilityModal
          show={offerEligibilityModal.show}
          offerFailedCriteria={offerEligibilityModal.failedOfferCriteria}
          offerDetails={
            cartRedirectOfferId ? cartRedirectOffer?.data : latestOffer?.data
          }
          closeModal={() =>
            setOfferEligibilityModal({
              show: false,
              failedOfferCriteria: '',
            })
          }
        />
      )}
      <div className={`${styles.cartDetailsWrap} `}>
        <Offcanvas
          show={showCart}
          onHide={hideCart}
          placement="end"
          style={{ width: '510px' }}
          scroll={false}
          className="cutsom-canvas"
        >
          {/* <a
            className="btn btn-custom back-arrow-btn f-s14 pt-4 ps-4 d-md-none"
            href="/menu"
          >
            Menu
          </a> */}
          <Offcanvas.Header closeButton className="justify-content-end pb-0">
            {/* <Offcanvas.Title>Cart Detail</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            <div className={styles.cartContent}>
              <h4 className="font-Cls text-md-center ps-md-0 ps-4 f-s22 h-24 medium_text my-4 f-w5">
                {showGroupCheckoutBtn
                  ? '  Cart Details'
                  : 'Share your group order link.'}
              </h4>
            </div>
            <div>
              {!showGroupCheckoutBtn && (
                <div className="d-flex justify-content-between mb-5">
                  <ShareLink
                    icon={<ShareLinkIcon className={'mb-2'} />}
                    text={'share link'}
                    onClick={() => setShareLinkModal(ShareModal.ShareLink)}
                  />

                  <ShareLink
                    icon={<CopyLinkIcon className={'mb-2'} />}
                    text={'copy link'}
                    onClick={copyHandler}
                  />
                  <ShareLink
                    icon={<ImportCsvIcon className={'mb-2'} />}
                    text={'Import (.csv file)'}
                    onClick={() => setShareLinkModal(ShareModal.ImportCsv)}
                  />
                </div>
              )}
              <div className="customCanvas">
                {!showGroupCheckoutBtn ? (
                  <>
                    <h4 className="font-Cls f-sm-s12 f-s22 mb-2 fw-bold cs_lt">
                      Group Order Cart
                    </h4>
                    <GroupOrderCartDetailCard
                      cartItem={'Salad & Soup Box Lunch'}
                      ingredient1={'Fresh Herb Vinaigrette'}
                      ingredient2={'Half calefornia cobb'}
                      ingredient3={'Butter Ranch'}
                      ingredient4={'Egg Noodles(+0.69)'}
                      name="Julia’s Order"
                      ingredients={''}
                    />
                    <GroupOrderCartDetailCard
                      cartItem={'Salad & Soup Box Lunch'}
                      ingredient1={'Fresh Herb Vinaigrette'}
                      ingredient2={'Half calefornia cobb'}
                      ingredient3={'Butter Ranch'}
                      ingredient4={'Egg Noodles(+0.69)'}
                      name="Julia’s Order"
                      ingredients={''}
                    />
                  </>
                ) : (
                  <>
                    <CartItemsList
                      syncingCart={syncingCart}
                      selectedOfferItem={selectedOfferItem}
                      subTotal={subTotal}
                      handleItemRemove={handleItemRemove}
                      loading={isDeleting || isUpdatingQuantity}
                      handleQuantityChange={handleQuantityChange}
                      cart={cartItems}
                    />
                  </>
                )}

                {(!isCartEmpty && !syncingCart) || isSignedInUser(user) ? (
                  <div className="shadow-divider mb-4"></div>
                ) : null}

                {isSignedInUser(user) ? (
                  <>
                    <div
                      className={`${styles.promo_code} new_form_design promo_code`}
                    >
                      <div className="form-group position-relative">
                        <FormField
                          name="redeem_dollars"
                          labelText="ENTER PROMO CODES HERE"
                          type="text"
                          onChange={(event) => setPromoCode(event.target.value)}
                        />
                        {applyPromoCodeIcon()}
                      </div>
                    </div>
                    <div className="shadow-divider"></div>
                    <OfferItemsList
                      offers={offers}
                      selectedOfferItem={selectedOfferItem}
                      title="Offers"
                      subTotal={subTotal}
                      isDisabled={syncingCart}
                    />
                    {/* <RedeemDollars /> */}
                  </>
                ) : null}
                {!isCartEmpty || isSignedInUser(user) ? (
                  <Billing
                    cartItems={cartItems}
                    isOrderTypeSelected={cart.isOrderTypeSelected}
                    orderType={cart.orderType}
                    authInfo={user}
                    offerDiscount={cart.offerDiscount}
                    disableCheckout={syncingCart}
                    handleTax={false}
                    setSubTotal={setSubTotal}
                    offerDiscountType={cart.offerDiscountType}
                    className={`cz-px-24 mt-5  ${
                      isSafari() ? styles.space_bottom_safari : null
                    } ${styles.space_bottom}`}
                    isCart={true}
                  />
                ) : null}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <CustomModal
        showModal={shareLinkModal !== ShareModal.close}
        title={''}
        closeModal={() => setShareLinkModal(ShareModal.close)}
        modalBodyClass=""
        modalDialogClasses={styles.shareLinkModal}
      >
        <ShareLinkModal
          title={isCSv ? 'Import .csv file' : 'ADDRESS INPUT'}
          desc={
            'Add email addresses and we will send out a message to your group!'
          }
          isCsv={isCSv}
        />
      </CustomModal>
    </>
  );
};

export default Cart;
